import axios from "axios";
import Cookies from "js-cookie";
import { keys } from "../utils/keys";
import { apiBaseUrl } from "./backend";
import { constants } from "../utils/constants";
import { getCustomerAxios } from "../utils/axios";
import { handleErrorLog } from "../utils/error_logs";
import { category, productListModel } from "./fragments";
import {
  handleGraphqlResponse,
  transformSearchResponse,
  returnSearchRequestSentCtx,
} from "../utils/functions";

export const getCategoryMeta = async ({ categoryid }) => {
  const variables = JSON.stringify({
    categoryfilters: { ids: { in: [categoryid] } },
  });

  try {
    const axiosRes = await axios.get(keys.general.graphqlUrl, {
      paramsSerializer: { indexes: null },
      params: {
        variables,
        query: `query ProductFilter ($categoryfilters: CategoryFilterInput) {
          categoryList (filters: $categoryfilters) {
            id name image meta_title meta_keywords meta_description
          }
        }`,
      },
    });
    const res = handleGraphqlResponse(axiosRes.data);
    return { data: res, error: null };
  } catch (error) {
    return { data: [], error: error.message };
  }
};

export const getProductMeta = async ({ productSKU }) => {
  const variables = JSON.stringify({
    productfilters: { sku: { eq: productSKU } },
  });

  try {
    const axiosRes = await axios.get(keys.general.graphqlUrl, {
      paramsSerializer: { indexes: null },
      params: {
        variables,
        query: `query ProductFilter ($productfilters: ProductAttributeFilterInput) {
          products (filter: $productfilters) {
            items {
              id name meta_title meta_keyword meta_description
              thumbnail { url label }
              extraVariable (breadcrumb_required: true) {
                brand_info { title }
              }
            }
          }
        }`,
      },
    });

    const res = handleGraphqlResponse(axiosRes.data);
    return { data: res, error: null };
  } catch (error) {
    return { data: [], error };
  }
};

export const getCategoryDetail = async ({ catId }) => {
  const variables = JSON.stringify({
    categoryfilters: { ids: { in: [catId] } },
  });

  try {
    const axiosRes = await axios.get(apiBaseUrl(), {
      paramsSerializer: { indexes: null },
      params: {
        variables,
        query: `query ProductFilter ($categoryfilters: CategoryFilterInput) {
          categoryList (filters: $categoryfilters) { ...Category }
        } ${category}`,
      },
    });

    const res = handleGraphqlResponse(axiosRes.data);
    return { data: res, error: null };
  } catch (error) {
    return { data: [], error: error.message };
  }
};

export const getProductListing = async ({
  page,
  pageSize,
  sortOrder,
  categoryId,
  specialCoupon,
  filtersObj = {},
  skuList = null,
  vehicleFilter = null,
}) => {
  try {
    let productfilters = {
      category_id: { eq: categoryId },
      price: { from: 0.01 },
    };
    if (skuList) {
      productfilters["sku"] = { in: skuList };
    }

    if (
      vehicleFilter &&
      vehicleFilter?.session_id &&
      vehicleFilter?.component_id
    ) {
      productfilters["vrmFilter"] = vehicleFilter;
    }

    for (const key in filtersObj) {
      if (!["page", "make", "model"].includes(key)) {
        productfilters[key] = { in: filtersObj[key] };
      }
    }

    const productModel = productListModel();
    const variables = {
      currentPage: parseInt(page),
      productfilters: productfilters,
      productPageSize: pageSize,
      categoryfilters: { ids: { in: [categoryId] } },
      productSort: {
        [sortOrder.field]: sortOrder.direction.toUpperCase(),
      },
    };

    const query = `
      query ProductFilter (
        $currentPage: Int,
        $productPageSize: Int
        $productSort: ProductAttributeSortInput
        $productfilters: ProductAttributeFilterInput
        $aggregationsFilter: AggregationsFilterInput
        $categoryfilters: CategoryFilterInput
      ) {
        categoryList(filters: $categoryfilters) { id name }
        products (
          sort: $productSort
          filter: $productfilters
          currentPage: $currentPage
          pageSize: $productPageSize
          specialCoupon: "${specialCoupon || ""}"
        ) {
          aggregations (filter: $aggregationsFilter) {
            ...AggregationFragment
          }
          items {
            ...Products
          }
          total_count
        }
      }
      ${productModel}
      ${aggregation}`;

    const axiosRes = await axios.post(apiBaseUrl(), {
      query,
      variables,
    });

    const res = handleGraphqlResponse(axiosRes.data);
    return { data: res, error: null };
  } catch (error) {
    return { data: [], errors: error.message };
  }
};

export const getFeaturedDeals = async (specialCoupon) => {
  const productModel = productListModel();
  try {
    const query = `query FeaturedAndSeasonalProducts(
        $featuredFilter: ProductAttributeFilterInput,
        $featuredSort: ProductAttributeSortInput,
        $featuredPageSize: Int
      ) {
        featured_products: products(
          filter: $featuredFilter,
          sort: $featuredSort,
          pageSize: $featuredPageSize
          specialCoupon: "${specialCoupon?.value || ""}"
        ) {
          total_count
          items {
            ...Products
          }
        }
      }
      ${productModel}`;

    const variables = JSON.stringify({
      featuredFilter: {
        featured_product: {
          eq: 1,
        },
        price: { from: 0.01 },
      },
      featuredSort: {
        featured_product_sort_order: "ASC",
      },
      featuredPageSize: 24, // Example value, adjust as needed
    });

    const axiosRes = await axios.get(apiBaseUrl(), {
      params: {
        query,
        variables,
      },
      paramsSerializer: {
        indexes: null, // Ensures proper serialization
      },
    });

    if (axiosRes.data.errors) {
      handleErrorLog({
        error: axiosRes.data.errors,
        additional_info: { specialCoupon },
        msg: "API Error while fetching featured deals",
      });
      throw new Error("GraphQL request failed");
    }
    return { data: axiosRes.data.data, error: null };
  } catch (error) {
    handleErrorLog({
      error,
      additional_info: { specialCoupon },
      msg: "Error while fetching featured deals",
    });
    return { data: [], error: error.message };
  }
};

export const searchProductsAndCategories = async ({
  searchText,
  pageSize = 8,
  currentPage = 1,
  catPageSize = 10,
  catCurrentPage = 1,
  specialCoupon,
  userViewHistoryGraphQL = [],
}) => {
  try {
    if (!userViewHistoryGraphQL.length) {
      const userViewHistory = Cookies.get(
        constants.cookies.searchProductClicked,
      )
        ? JSON.parse(Cookies.get(constants.cookies.searchProductClicked))
        : [];
      userViewHistoryGraphQL = userViewHistory
        .map((item) => `{ sku: "${item.sku}", dateTime: "${item.dateTime}" }`)
        .join(", ");
    }
    const startTime = performance.now();
    const textWithoutQuotes = searchText?.replaceAll('"', "");
    const query = `query {
      productSearch (
        phrase: "${textWithoutQuotes}"
        filter: [
          { attribute: "price", range: { from: 0.01 } }
          {attribute: "visibility", in: ["Search", "Catalog, Search"]}
        ]
        context: {
          customerGroup: "${process.env.NEXT_PUBLIC_CUSTOMER_GROUP}"
          userViewHistory: [${userViewHistoryGraphQL}]
        }
        page_size: ${pageSize}
        current_page: ${currentPage}
        cate_page_size: ${catPageSize}
        cate_current_page: ${catCurrentPage}
        special_coupon: "${specialCoupon || ""}"
      ) {
        request_id total_count special_coupon_applied suggestions related_terms
        search_trems { query_text redirect }
        page_info {
          current_page
          page_size total_pages cate_page_size cate_total_count cate_current_page
        }
        categories {
          id name link image
        }
        items {
          productView {
            breadcrumbs art_nr externalId best_seller is_recommended sold_in_pairs warranty_guaranteed_text stop_start_compatible inStock sku name urlKey
            brand_info { title image brand_tier }
            images { url label }
            attributes (roles: []) {
              name label value
            }
            price {
              final {
                amount { value currency }
              }
              regular {
                amount { value currency }
              }
            }
          }
        }
      }
    }`;
    const axiosRes = await axios.post(apiBaseUrl(), { query });
    const endTime = performance.now();
    const executionTime = (endTime - startTime).toFixed(2);
    const res = handleGraphqlResponse(axiosRes.data);
    const inputSearchContext = returnSearchRequestSentCtx({
      searchText,
      pageSize,
      currentPage,
      request_id: res?.productSearch?.request_id,
    });
    return {
      data: transformSearchResponse(
        res,
        executionTime,
        currentPage,
        pageSize,
        inputSearchContext,
      ),
      error: null,
    };
  } catch (error) {
    return { data: [], error: error.message };
  }
};

export const getCarPartsCategories = async ({ catId, showChild = true }) => {
  try {
    const variables = JSON.stringify({
      filters: { ids: { in: [catId] } },
    });

    const withSubCat = `{
      id name description
      breadcrumbs {
        category_id category_name category_level category_url_path category_url_key
      }
      children {
        id name description image url_path component_id
        children {
          id name image url_path component_id
        }
      }
    }`;

    const withoutSubCat = `{
      id name description
      breadcrumbs {
        category_id category_name category_level category_url_path category_url_key
      }
      children {
        id name description image url_path component_id
      }
    }`;

    const axiosRes = await axios.get(apiBaseUrl(), {
      params: {
        variables,
        query: `query getCategoryDetails ($filters: CategoryFilterInput) {
          categories(filters: $filters) {
            items ${!showChild ? withoutSubCat : withSubCat}
          }
        }`,
      },
      paramsSerializer: {
        indexes: null, // Prevent array indices from being added to query parameters
      },
    });
    const res = handleGraphqlResponse(axiosRes.data);
    return { data: res, error: null };
  } catch (error) {
    return { data: [], error: error.message };
  }
};

export const notifyStockAlertMail = async (product_id) => {
  try {
    const currentAxios = await getCustomerAxios();
    const axiosRes = await currentAxios.post(keys.general.backendGraphqlUrl, {
      query: `mutation ProductAlertStock($input: ProductAlertInput!) {
        productAlertStock (input: $input) {
          status
        }
      }`,
      variables: { input: { product_id } },
    });
    const res = handleGraphqlResponse(axiosRes);
    let err;
    if (res?.errors) {
      err = res?.errors[0].message;
    } else {
      err = null;
    }
    return { data: res.data, error: err };
  } catch (error) {
    return { data: null, error };
  }
};

export const unsubscribeNotifyStockAlertMail = async () => {
  try {
    const currentAxios = await getCustomerAxios();
    const axiosRes = await currentAxios.post(keys.general.backendGraphqlUrl, {
      query: `mutation { unSubscribeAllProductStock { status } }`,
    });
    const res = handleGraphqlResponse(axiosRes);
    let err;
    if (res?.errors) {
      err = res?.errors[0].message;
    } else {
      err = null;
    }
    return { data: res.data, error: err };
  } catch (error) {
    return { data: null, error };
  }
};

export const notifyStockAlertMailGuest = async (payload) => {
  try {
    const axiosRes = await axios.post(apiBaseUrl(), {
      query: `mutation GuestProductAlertStock($input: GuestProductAlertInput!) {
        guestProductAlertStock (input: $input) { status }
      }`,
      variables: { input: payload },
    });
    const res = handleGraphqlResponse(axiosRes);
    let err;
    if (res?.errors) {
      err = res?.errors[0].message;
    } else {
      err = null;
    }
    return { data: res.data, error: err };
  } catch (error) {
    return { data: null, error };
  }
};

export const unsubscribeNotifyStockAlertMailGuest = async (payload) => {
  try {
    const axiosRes = await axios.post(apiBaseUrl(), {
      query: `mutation UnSubscribeGuestProductAlertStock($input: GuestProductAlertUnsubscribeInput!) {
        unSubscribeGuestProductAlertStock(input: $input) {
          status email
        }
      }`,
      variables: { input: payload },
    });
    const res = handleGraphqlResponse(axiosRes);
    let err;
    if (res?.errors) {
      err = res?.errors[0].message;
    } else {
      err = null;
    }
    return { data: res.data, error: err };
  } catch (error) {
    return { data: null, error };
  }
};
